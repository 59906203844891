import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  BooleanField,
  usePermissions,
  FunctionField,
} from "react-admin";
import { Stack } from "@mui/material";
import { get, isEmpty } from "lodash";

const ProcessedRequests = (props: object) => {
  const { permissions } = usePermissions();
  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Payout Type"
      source="payoutType"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "BankTransfer", name: "Bank Transfer" },
      ]}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton filters={transactionFilters} />
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search By UserName"
        source="userName"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "Username must be at least 10 characters long";
          }
          return undefined;
        }}
      />
      <SearchInput
        placeholder="Search By Order Id"
        source="orderId"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search By Transaction Id"
        source="_id"
        resettable
        alwaysOn
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Processed Requests</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="_id" label="Transaction Id" />
          <TextField source="orderId" label="Order Id" />
          <TextField source="userName" label="User Name" />
          <TextField source="amount" />
          <TextField source="paymentGateway" />
          <TextField source="payoutType" />
          <BooleanField source="autoPay" />
          <FunctionField
            label="Payout Status"
            render={(record: any) => {
              if (record.payoutStatus) {
                if (record.payoutStatus === "PENDING") {
                  return <ChipField source="payoutStatus" color="warning" />;
                } else if (record.payoutStatus === "SUCCESS") {
                  return <ChipField source="payoutStatus" color="success" />;
                } else {
                  return <ChipField source="payoutStatus" color="error" />;
                }
              } else {
                if (record.cashfreePayoutStatus === "PENDING") {
                  return (
                    <ChipField source="cashfreePayoutStatus" color="warning" />
                  );
                } else if (record.cashfreePayoutStatus === "SUCCESS") {
                  return (
                    <ChipField source="cashfreePayoutStatus" color="success" />
                  );
                } else {
                  return (
                    <ChipField source="cashfreePayoutStatus" color="error" />
                  );
                }
              }
            }}
          />
          <FunctionField
            label="Account Details"
            render={(record: any) => {
              return !isEmpty(get(record, "vpa")) ? (
                <TextField source="vpa" />
              ) : (
                <>
                  <TextField source="account.accountNo" />
                  <br />
                  <TextField source="account.ifscCode" />
                </>
              );
            }}
          />
          <DateField source="createdAt" showTime label="Requested At" />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default ProcessedRequests;
