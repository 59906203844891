import React, { useState, useEffect } from "react";
import { CircularProgress, Box } from "@mui/material";
import { DateRangePicker } from "rsuite";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import config from "../config";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DataGrid } from "@mui/x-data-grid";

const breakpoints = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
};

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
    }`;
};

const getDateArray = (date1: Date, date2: Date) => {
  const dateArray = [];
  let currentDate = new Date(date1);
  while (currentDate <= date2) {
    dateArray.push(formatDate(currentDate));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }
  return dateArray;
};

const RevenueReport = () => {
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [stats, setStats] = useState({
    signupResult: [],
    referralResult: [],
    joinFeeResult: [],
    gameResult: [],
    tableWiseResult: [],
  });
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  startDate.setDate(currentDate.getDate() - 30);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startDate,
    currentDate,
  ]);

  const dynamicHeight = "80vh";

  // eslint-disable-next-line
  const [csvData, setCSVData] = useState([]);

  const handleDateSelect = (value: any) => {
    if (value) {
      setDateRange(value);
    } else {
      setDateRange([new Date(), new Date()]);
    }
  };

  const dateArray = getDateArray(dateRange[0], dateRange[1]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();
      const filter = {
        startDate,
        endDate,
      };

      const apiEndpoints = [
        `${config.REST_ENDPOINT}/daily-reports?key=${config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
      ];

      const apiRequests = apiEndpoints.map((endpoint) =>
        axios.get(endpoint, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
      );

      const responses = await Promise.all(apiRequests);
      // eslint-disable-next-line
      const {
        signupResult,
        referralResult,
        joinFeeResult,
        gameResult,
        tableWiseResult,
      } = responses[0].data;

      setStats({
        signupResult,
        referralResult,
        joinFeeResult,
        gameResult,
        tableWiseResult,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange]);

  const dateWiseData = (date: string) => {
    const {
      signupResult,
      referralResult,
      joinFeeResult,
      gameResult,
      tableWiseResult,
    } = stats;
    const dailyGameResult: any = gameResult.find(
      (item: any) => item._id === date
    );
    const dailySignupResult: any = signupResult.find(
      (item: any) => item._id === date
    );
    const dailyReferralResult: any = referralResult.find(
      (item: any) => item._id === date
    );
    const dailyJoinFeeResult: any = joinFeeResult.find(
      (item: any) => item._id === date
    );
    const dailyTableWiseResult: any = tableWiseResult.filter(
      (item: any) => item._id.date === date
    );

    const getTableWiseValues = (amount: number) => {
      const result = dailyTableWiseResult.find(
        (item: any) => item._id.joinAmount == amount
      );
      return result ? result : { roundCount: 0, commission: 0 };
    };

    const totalSignup = dailySignupResult
      ? dailySignupResult.dayTotalUserCount
      : 0;
    const totalSignupBonus = dailySignupResult
      ? dailySignupResult.dayTotalSignupBonus
      : 0;
    const totalReferral = dailyReferralResult
      ? dailyReferralResult.dayTotalReferralCount
      : 0;
    const totalReferralBonus = dailyReferralResult
      ? dailyReferralResult.dayTotalreferralAmount
      : 0;
    const totalGames = dailyGameResult ? dailyGameResult.tableCount : 0;
    const totalRounds = dailyGameResult ? dailyGameResult.roundCount : 0;
    const totalCommission = dailyGameResult ? dailyGameResult.commission : 0;
    const totalJoinFee = dailyJoinFeeResult
      ? parseFloat(dailyJoinFeeResult.joinFee.toFixed(2))
      : 0;
    const totalUsedSignupBonus = parseFloat((totalJoinFee / 10).toFixed(2));
    const totalProfit =
      totalCommission - totalUsedSignupBonus - totalReferralBonus;

    return {
      date,
      totalSignup,
      totalSignupBonus,
      totalReferral,
      totalReferralBonus,
      totalGames,
      totalRounds,
      totalJoinFee,
      totalCommission,
      totalUsedSignupBonus,
      totalProfit,
      roundCount_1: getTableWiseValues(1).roundCount,
      commission_1: getTableWiseValues(1).commission,
      roundCount_5: getTableWiseValues(5).roundCount,
      commission_5: getTableWiseValues(5).commission,
      roundCount_10: getTableWiseValues(10).roundCount,
      commission_10: getTableWiseValues(10).commission,
      roundCount_25: getTableWiseValues(25).roundCount,
      commission_25: getTableWiseValues(25).commission,
      roundCount_40: getTableWiseValues(40).roundCount,
      commission_40: getTableWiseValues(40).commission,
      roundCount_50: getTableWiseValues(50).roundCount,
      commission_50: getTableWiseValues(50).commission,
      roundCount_100: getTableWiseValues(100).roundCount,
      commission_100: getTableWiseValues(100).commission,
      roundCount_250: getTableWiseValues(250).roundCount,
      commission_250: getTableWiseValues(250).commission,
    };
  };

  const formatDataForCSV = () => {
    const csvData = [];
    csvData.push([
      "Date",
      "Total Signup Users",
      "Total Signup Bonus",
      "Total Referral Count",
      "Total Referral Amount",
      "Total Games Count",
      "Total Rounds Count",
      "Total Join Fee",
      "Total Commission",
      "Total Used Signup Bonus",
      "Total Profit",
      "1R Round Count",
      "1R Commission",
      "5R Round Count",
      "5R Commission",
      "10R Round Count",
      "10R Commission",
      "25R Round Count",
      "25R Commission",
      "40R Round Count",
      "40R Commission",
      "50R Round Count",
      "50R Commission",
      "100R Round Count",
      "100R Commission",
      "250R Round Count",
      "250R Commission",
    ]);

    dateArray.forEach((date) => {
      const {
        totalSignup,
        totalSignupBonus,
        totalReferral,
        totalReferralBonus,
        totalGames,
        totalRounds,
        totalJoinFee,
        totalCommission,
        totalUsedSignupBonus,
        totalProfit,
        roundCount_1,
        commission_1,
        roundCount_5,
        commission_5,
        roundCount_10,
        commission_10,
        roundCount_25,
        commission_25,
        roundCount_40,
        commission_40,
        roundCount_50,
        commission_50,
        roundCount_100,
        commission_100,
        roundCount_250,
        commission_250,
      } = dateWiseData(date);

      csvData.push([
        date,
        totalSignup,
        totalSignupBonus,
        totalReferral,
        totalReferralBonus,
        totalGames,
        totalRounds,
        totalJoinFee,
        totalCommission,
        totalUsedSignupBonus,
        totalProfit,
        roundCount_1,
        commission_1,
        roundCount_5,
        commission_5,
        roundCount_10,
        commission_10,
        roundCount_25,
        commission_25,
        roundCount_40,
        commission_40,
        roundCount_50,
        commission_50,
        roundCount_100,
        commission_100,
        roundCount_250,
        commission_250,
      ]);
    });

    return csvData;
  };

  return (
    <Box>
      <h2 style={{ fontFamily: "Playfair Display" }}>Revenue Report</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
          justifyContent: "space-between",
        }}
      >
        <div>
          <p style={{ marginRight: "10px" }}>
            <strong>Select Date Range:</strong>

            <DateRangePicker value={dateRange} onChange={handleDateSelect} />
          </p>
        </div>

        <div>
          <CSVLink
            data={formatDataForCSV()}
            filename={`daily_report_${formatDate(dateRange[0])}_${formatDate(
              dateRange[1]
            )}.csv`}
            className="csv-download-link"
          >
            <span>Download CSV</span>
          </CSVLink>
        </div>
      </div>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Display data in a DataGrid */}
          <div style={{ height: dynamicHeight, width: "100%" }}>
            <DataGrid
              rows={dateArray
                .map((date) => ({
                  id: date,
                  ...dateWiseData(date),
                }))
                .reverse()}
              columns={[
                {
                  field: "date",
                  headerName: "Date",
                  width: 200,
                  valueGetter: (params) => {
                    const date = params.row.date;
                    const formattedDate = formatDate(new Date(date));
                    return formattedDate.split("-").reverse().join("-");
                  },
                },
                {
                  field: "totalSignup",
                  headerName: "Total Signup Users",
                  width: 200
                },
                {
                  field: "totalSignupBonus",
                  headerName: "Total Signup Bonus",
                  width: 200
                },
                {
                  field: "totalReferral",
                  headerName: "Total Referral Count",
                  width: 200
                },
                {
                  field: "totalReferralBonus",
                  headerName: "Total Referral Amount",
                  width: 200
                },
                {
                  field: "totalGames",
                  headerName: "Total Games Count",
                  width: 200
                },
                {
                  field: "totalRounds",
                  headerName: "Total Rounds Count",
                  width: 200
                },
                {
                  field: "totalJoinFee",
                  headerName: "Total Join Fee",
                  width: 200
                },
                {
                  field: "totalCommission",
                  headerName: "Total Commission",
                  width: 200
                },
                {
                  field: "totalUsedSignupBonus",
                  headerName: "Total Used Signup Bonus",
                  width: 200
                },
                {
                  field: "totalProfit",
                  headerName: "Total Profit",
                  width: 200
                },
                {
                  field: "roundCount_1",
                  headerName: "1R Round Count",
                  width: 200
                },
                {
                  field: "commission_1",
                  headerName: "1R Commission",
                  width: 200
                },
                {
                  field: "roundCount_5",
                  headerName: "5R Round Count",
                  width: 200
                },
                {
                  field: "commission_5",
                  headerName: "5R Commission",
                  width: 200
                },
                {
                  field: "roundCount_10",
                  headerName: "10R Round Count",
                  width: 200
                },
                {
                  field: "commission_10",
                  headerName: "10R Commission",
                  width: 200
                },
                {
                  field: "roundCount_25",
                  headerName: "25R Round Count",
                  width: 200
                },
                {
                  field: "commission_25",
                  headerName: "25R Commission",
                  width: 200
                },
                {
                  field: "roundCount_40",
                  headerName: "40R Round Count",
                  width: 200
                },
                {
                  field: "commission_40",
                  headerName: "40R Commission",
                  width: 200
                },
                {
                  field: "roundCount_50",
                  headerName: "50R Round Count",
                  width: 200
                },
                {
                  field: "commission_50",
                  headerName: "50R Commission",
                  width: 200
                },
                {
                  field: "roundCount_100",
                  headerName: "100R Round Count",
                  width: 200
                },
                {
                  field: "commission_100",
                  headerName: "100R Commission",
                  width: 200
                },
                {
                  field: "roundCount_250",
                  headerName: "250R Round Count",
                  width: 200
                },
                {
                  field: "commission_250",
                  headerName: "250R Commission",
                  width: 200
                },
              ]}
            />
          </div>
        </>
      )}
    </Box>
  );
};
export default RevenueReport;
