import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DateRangePicker } from "rsuite";
import axios from "axios";
import config from "../config";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import StatsCard from "../components/StatsCard";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "rsuite/dist/rsuite.css";

const GameplayDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [stats, setStats] = useState<any>({});

  var date = new Date();
  date.setDate(date.getDate());
  const [dateRange, setDateRange] = useState<[Date, Date]>([date, new Date()]);

  const [gameType, setGameType] = useState("all");
  const colorPalette = [
    "#ADD8E6",
    "#6699CC",
    "#009933",
    "#FF33BB",
    "#B033FF",
    "#FFC933",
  ];

  const handleDateSelect = (value: any) => {
    setDateRange(value);
  };

  const handleGameTypeSelect = (selectedGameType: string) => {
    setGameType(selectedGameType);
  };

  const fetchData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const startDateFetch = dateRange[0];
      startDateFetch.setHours(0, 0, 0, 0);
      const startDate = new Date(
        startDateFetch.getTime() - startDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const endDateFetch = dateRange[1];
      endDateFetch.setHours(23, 59, 59, 999);
      const endDate = new Date(
        endDateFetch.getTime() - endDateFetch.getTimezoneOffset() * 60000
      ).toJSON();

      const filter = {
        startDate,
        endDate,
        gameType,
      };

      setLoading(true);
      const response = await axios.get(
        `${config.REST_ENDPOINT}/gameplay-dashboard?key=${
          config.MAINTENANCE_BYPASS_KEY
        }&filter=${encodeURIComponent(JSON.stringify(filter))}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response && response.data) {
        const { data } = response;
        setStats(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [dateRange, gameType]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
          marginBottom: "auto",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Game Dashboard</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
          marginTop: "10px",
        }}
      >
        <p style={{ marginRight: "10px" }}>
          <strong>Select Date Range:</strong>
        </p>
        <DateRangePicker value={dateRange} onChange={handleDateSelect} />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          marginRight: "20px",
        }}
      >
        <Typography style={{ marginRight: "10px" }}>
          <strong>Select Game Type:</strong>
        </Typography>

        <Button
          variant={gameType === "all" ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleGameTypeSelect("all")}
          style={{ marginLeft: "10px" }}
        >
          All
        </Button>

        <Button
          variant={gameType === "multiplayer" ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleGameTypeSelect("multiplayer")}
          style={{ marginLeft: "10px" }}
        >
          MultiPlayer
        </Button>
        <Button
          variant={gameType === "twoplayer" ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleGameTypeSelect("twoplayer")}
          style={{ marginLeft: "10px" }}
        >
          TwoPlayer
        </Button>
      </div>

      {/* Stats Cards */}
      <Grid
        container
        justifyContent="left"
        style={{ marginTop: "10px", marginBottom: "20px" }}
      >
        {Object.keys(stats)
          .filter(
            (key) =>
              ![
                "totalgames",
                "totalgamerounds",
                "dayWiseTotalGames",
                "dayWiseTotalGameRounds",
              ].includes(key)
          )
          .map((key, index) => (
            <Grid item xs={12} sm={6} md={3} key={key}>
              <StatsCard
                title={
                  <Typography fontWeight="bold">{`Total ${key}₹ Games`}</Typography>
                }
                value={stats[key] ?? 0}
                icon={
                  <SportsEsportsIcon
                    style={{
                      fontSize: "20px",
                      color: colorPalette[index % colorPalette.length],
                    }}
                  />
                }
              />
            </Grid>
          ))}
      </Grid>

      {/* Loading Spinner */}
      {loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default GameplayDashboard;
