import { memo, useEffect, useState } from "react";
import { Box, LinearProgress } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { has, set } from "lodash";
import querystring from "query-string";

import { callRestApi } from "../utils/callRestApi";
import { convertToDotNotation } from "../utils/dotNotationConverter";

const SimpleTable = (props: any) => {
  const { data = [], endPointData, dataMode = "client", filter = {} } = props;
  const columns: GridColDef[] = props.columns;

  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [apiMeta, setApiMeta] = useState({
    totalCount: 0,
    skip: 0,
    count: 5,
  });
  const [payloadMeta, setPayloadMeta] = useState({
    count: 5,
    skip: 0,
    page: 0,
    filter: {},
    sortBy: "createdAt",
    sortDir: -1,
  });

  if (!has(data, "id")) {
    data.map((record: any, index: number) => {
      return (record["id"] = index);
    });
  }

  useEffect(() => {
    if (dataMode === "server") {
      fetchData();
    }
    // eslint-disable-next-line
  }, [payloadMeta]);

  const handlePaginationChange = (event: any) => {
    const { page, pageSize } = event;
    setPayloadMeta((prev) => ({
      ...prev,
      skip: Math.abs(page * payloadMeta.count),
      count: pageSize,
      page,
    }));
  };

  const handleSortChange = (event: any) => {
    try {
      const { field, sort } = event[0];
      setPayloadMeta((prev) => ({
        ...prev,
        sortBy: field,
        sortDir: sort === "asc" ? 1 : -1,
      }));
    } catch (error) {
      setPayloadMeta((prev) => ({
        ...prev,
        sortBy: "createdAt",
        sortDir: -1,
      }));
    }
  };

  const handleFilterChange = (event: any) => {
    try {
      const { field, value } = event?.items[0];
      setPayloadMeta((prev) => {
        const filterValue: object = {};
        set(filterValue, field, value);
        return {
          ...prev,
          filter: { ...prev.filter, ...filterValue },
        };
      });
    } catch (error) {
      setPayloadMeta((prev) => ({
        ...prev,
      }));
    }
  };

  const fetchData = async () => {
    const query = {
      filter: JSON.stringify(
        convertToDotNotation({ ...payloadMeta.filter, ...filter })
      ),
      count: payloadMeta.count,
      skip: payloadMeta.skip,
      sortBy: payloadMeta.sortBy,
      sortDir: payloadMeta.sortDir,
    };
    setLoading(true);
    try {
      const apiResponse = await callRestApi(
        endPointData?.apiEndpoint,
        endPointData?.method || "GET",
        {},
        querystring.stringify(query)
      );
      setApiData(() => apiResponse?.data?.items);
      setApiMeta(() => apiResponse?.data?.meta);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={dataMode === "client" ? data : apiData}
        columns={columns}
        rowCount={dataMode === "client" ? data.length : apiMeta.totalCount}
        paginationModel={{
          page: payloadMeta.page,
          pageSize: payloadMeta.count,
        }}
        loading={loading}
        pagination
        paginationMode={dataMode}
        pageSizeOptions={[5, 10, 20, 30, 50, 100]}
        disableRowSelectionOnClick
        slots={{ loadingOverlay: LinearProgress, toolbar: GridToolbar }}
        onFilterModelChange={handleFilterChange}
        onSortModelChange={handleSortChange}
        onPaginationModelChange={handlePaginationChange}
        getRowId={(row) => row._id}
      />
    </Box>
  );
};

export default memo(SimpleTable);
