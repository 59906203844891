import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useRefresh } from "react-admin";
import { DataGrid } from "@mui/x-data-grid";
import { ReactQueryDevtools } from "react-query/devtools";
import config from "../../config";
import { getItemWithExpiry } from "../../utils";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Big from "big.js";
import { usePermissions } from "react-admin";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormLabel,
  Switch,
} from "@mui/material";

const GameOngoing = () => {
  const refresh = useRefresh();
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState([]);
  const [usernameFilter, setUsernameFilter] = useState("");
  const [tableIdFilter, setTableIdFilter] = useState("");
  const [amountFilter, setAmountFilter] = useState("");
  const [totalLivePlayers, setTotalLivePlayers] = useState(0);
  const [totalPlayers, setTotalPlayers] = useState(0);
  const [uniqueTableIds, setUniqueTableIds] = useState(0);
  const [tableStatus, setTableStatus] = useState("green");
  const [stuckTables, setStuckTables] = useState<string[]>([]);
  const [clearConfirmation, setClearConfirmation] = useState(false);
  const [tableIdToDelete, setTableIdToDelete] = useState<string | null>(null);
  const [showStuckTables, setShowStuckTables] = useState(false);
  const [stuckTablesCount, setStuckTablesCount] = useState(0);

  // eslint-disable-next-line
  const renderActiveStatus = (active: any) => {
    return active ? "Active" : "Inactive";
  };
  const breakpoints = {
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
  };

  const { permissions } = usePermissions();
  const isAdmin = permissions.includes("admin");

  const openClearConfirmation = (tableId: string) => {
    setTableIdToDelete(tableId);
    setClearConfirmation(true);
  };

  const closeClearConfirmation = () => {
    setClearConfirmation(false);
  };

  const handleClearConfirmation = async (confirmed: boolean) => {
    closeClearConfirmation();
    if (confirmed && tableIdToDelete) {
      await handleClearStuckTable(tableIdToDelete);
    }
  };

  const handleClearStuckTable = async (tableId: string) => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        return;
      }
      // eslint-disable-next-line
      const response = await axios.post(
        `${config.REST_ENDPOINT}/clear-table?tableId=${tableId}&key=${config.MAINTENANCE_BYPASS_KEY}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      // Update the gameData state by removing the cleared table
      setGameData((prevGameData) =>
        prevGameData.filter((game: any) => game.tableId !== tableId)
      );

      toast.success("Table deleted successfully!");
      refresh();
    } catch (error: any) {
      toast(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
        { type: "error" }
      );
    }
  };

  const columns = [
    {
      field: "tableId",
      headerName: "Table ID",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          <Link
            to={`${params?.row.tableId}`}
            style={{
              color: showStuckTables && stuckTables.includes(params?.row.tableId) ? "red" : "",
            }}
          >
            {`${params.row.tableId}_${params?.row.rounds}`}
          </Link>
          {showStuckTables && stuckTables.includes(params?.row.tableId) && (
            renderClearButton(params.row.tableId)
          )}
        </div>
      ),
    },
    // { field: "gameType", headerName: "Game Type", flex: breakpoints.md },
    { field: "tableAmount", headerName: "Join Amount", flex: breakpoints.sm },
    {
      field: "players",
      headerName: "Players",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              <Link to={`/users/${player.userId}/show`}>{player.username}</Link>
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "playerStatus",
      headerName: "Player Status",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{ color: player.active ? "green" : "red", padding: "5px" }}
            >
              {player.active ? "Active" : "Inactive"}
            </div>
          ))}
        </div>
      ),
    },
    // { field: "joinedRoundNo", headerName: "Player RoundNo", flex: breakpoints.md },
    {
      field: "startAmount",
      headerName: "Start Amount",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              {new Big(player.startAmount).toFixed(2)}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "roundAmount",
      headerName: "Round Amount",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              {new Big(player.roundAmount).toFixed(2)}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "walletBalance.main",
      headerName: "Wallet Balance",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              {player.walletBalance?.main !== undefined
                ? new Big(player.walletBalance.main).toFixed(2)
                : "N/A"}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "amount.winning",
      headerName: "Winning Amount",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              {new Big(player.amount?.winning).toFixed(2)}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "amount.bonus",
      headerName: "Bonus Amount",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              {player.amount?.bonus !== undefined
                ? new Big(player.amount.bonus).toFixed(2)
                : "N/A"}
            </div>
          ))}
        </div>
      ),
    },
    {
      field: "amount.main",
      headerName: "Main Amount",
      flex: breakpoints.md,
      renderCell: (params: any) => (
        <div>
          {params.row.players.map((player: any) => (
            <div
              key={player.userId}
              style={{
                padding: "5px",
              }}
            >
              {new Big(player.amount?.main).toFixed(2)}
            </div>
          ))}
        </div>
      ),
    },
  ];

  const rows = gameData
    ? gameData.reduce((accumulator: any[], game: any) => {
      const existingTable = accumulator.find(
        (item) => item.tableId === game.tableId
      );

      if (existingTable) {
        existingTable.players.push(...game.players);
      } else {
        accumulator.push({
          id: game.tableId,
          tableId: game.tableId,
          gameStatus: game.gameStatus,
          gameType: game.tableType.gameType,
          tableAmount: game.tableType.minJoinAmount,
          rounds: game.roundNo,
          players: [...game.players],
        });
      }

      return accumulator;
    }, [])
    : [];

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 0,
  });


  const handleSwitchChange = () => {
    setShowStuckTables(!showStuckTables);
  };

  const fetchGameData = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user || !user.token) {
        toast.error("You have no access");
        setLoading(false);
        return;
      }

      const { page, pageSize } = paginationModel;
      let query = "";
      query += usernameFilter ? `&userName=${usernameFilter}` : "";
      query += tableIdFilter ? `&tableId=${tableIdFilter}` : "";
      query += amountFilter ? `&amount=${amountFilter}` : "";
      query += `&skip=${pageSize * page}&count=${pageSize}`;
      const response = await axios.get(
        `${config.REST_ENDPOINT}/game-table?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const stuckTables = showStuckTables
        ? response.data.stuckTables.map((game: { tableId: any }) => game.tableId)
        : [];
      let filteredData = showStuckTables
        ? response.data.stuckTables
        : response.data.gameTables;
      setStuckTables(stuckTables);
      setTotalLivePlayers(response.data.playerCount);
      setTotalPlayers(response.data.playerCount);
      setUniqueTableIds(response.data.tableCount);

      setStuckTablesCount(response.data.stuckTableCount);
      setGameData(filteredData);
      setTableStatus(response.data.tableCount > 0 ? "green" : "red");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching game data:", error);
      setLoading(false);
      toast.error("Error fetching game data");
    }
  };

  useEffect(() => {
    let interval: any;
    const timeout = setTimeout(() => {
      fetchGameData();
      interval = setInterval(async () => {
        await fetchGameData();
        refresh();
      }, 20000);
    }, 800);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [
    refresh,
    tableIdFilter,
    usernameFilter,
    amountFilter,
    paginationModel,
    showStuckTables,
  ]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const renderClearButton = (tableId: any) => {
    const buttonStyle = {
      marginLeft: "28px",
      padding: "2px 2px",
      backgroundColor: "#FF6500",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
    };

    return isAdmin ? (
      <div>
        <button
          ref={buttonRef}
          onClick={() => openClearConfirmation(tableId)}
          style={buttonStyle}
        >
          Clear
        </button>
        <Dialog open={clearConfirmation} onClose={closeClearConfirmation}>
          <DialogTitle>Confirm Clear Table</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to clear the table?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleClearConfirmation(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleClearConfirmation(true)}
              color="primary"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null;
  };

  return (
    <div>
      <h2 style={{ fontFamily: "Playfair Display" }}>Live Games</h2>
      <div>
        <div
          style={{
            display: "inline-block",
            marginRight: "10px",
            marginTop: "5px",
          }}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "80%",
              backgroundColor: tableStatus,
              display: "inline-block",
              marginRight: "5px",
            }}
          />
          <b> Live Tables: {uniqueTableIds} </b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <div
            style={{
              display: "inline-block",
              marginRight: "10px",
              marginTop: "8px",
            }}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "80%",
                backgroundColor: tableStatus,
                display: "inline-block",
                marginRight: "5px",
              }}
            />
            <b> Live Players: {totalLivePlayers} </b>
          </div>
        </div>

        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "80%",
            backgroundColor: stuckTablesCount > 0 ? "red" : "green",
            display: "inline-block",
            marginRight: "5px",
          }}
        />
        <b> Stuck Tables : {stuckTablesCount} </b>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Username"
              variant="outlined"
              fullWidth
              value={usernameFilter}
              inputProps={{ minLength: 10 }}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setUsernameFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Table ID"
              variant="outlined"
              fullWidth
              value={tableIdFilter}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setTableIdFilter(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Search by Join Amount"
              variant="outlined"
              fullWidth
              value={amountFilter}
              inputProps={{ minLength: 10 }}
              onChange={(e) => {
                setPaginationModel({ page: 0, pageSize: 10 });
                setAmountFilter(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Paper elevation={3} style={{ marginTop: "20px", padding: "20px" }}>
          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 0px 6px 0px",
            }}
          >
            <FormLabel component="legend">Show Stuck Tables</FormLabel>
            <Switch checked={showStuckTables} onChange={handleSwitchChange} />
          </Grid>

          <DataGrid
            columns={columns}
            rows={rows}
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 80, 100]}
            rowCount={totalPlayers}
            paginationMode="server"
            autoHeight
            rowHeight={200}
          />
        </Paper>
        <ReactQueryDevtools />
      </div>
    </div>
  );
};

export default GameOngoing;
