import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  Filter,
  SearchInput,
  usePermissions,
  NumberField,
  FunctionField,
  FilterForm,
  DateTimeInput,
} from "react-admin";
import Big from "big.js";
import UserNameHyperlink from "../../components/UserNameHyperlink";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { Typography } from "@mui/material";

const GameHistory = (props: object) => {
  const { permissions } = usePermissions();

  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const transactionFilters = [
    <DateTimeInput source="startDate" alwaysOn />,
    <DateTimeInput source="endDate" alwaysOn />,
  ];

  const ListActions = () => (
    <TopToolbar>
      <SelectColumnsButton />
      {permissions.includes("admin") && <ExportButton />}
    </TopToolbar>
  );

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search User Name"
        source="userName"
        resettable
        alwaysOn
      />
      <SearchInput
        placeholder="Search Join Amount"
        source="tableType.minJoinAmount"
        resettable
        alwaysOn
      />

      <SearchInput
        placeholder="Search Table ID"
        source="tableId"
        resettable
        alwaysOn
      />
    </Filter>
  );

  const valueFormatter = (value: Big.BigSource) => {
    const formattedValue = new Big(value);
    return formattedValue.toFixed(2);
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Game Histories</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <Typography fontWeight="bold">Select Date Range:</Typography>
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink source="userName" to="userId" label="User Name" />
          <FunctionField
            label="Table Id"
            render={(record: any) => (
              <Link to={record?.tableId}>{record?.tableId}</Link>
            )}
          />
          <TextField source="tableType.gameType" label="Game Type" />
          <NumberField
            source="tableType.minJoinAmount"
            label="Min Join Amount"
          />
          <NumberField
            source="tableType.initialBetAmount"
            label="Initial Bet Amount"
          />
          <NumberField source="tableType.potLimit" label="Pot Limit" />
          <TextField source="currency" label="Currency" />
          <FunctionField
            label="Start Amount"
            render={(record: any) => valueFormatter(record.startAmount)}
          />
          <FunctionField
            label="End Amount"
            render={(record: any) => valueFormatter(record.endAmount)}
          />{" "}
          <TextField source="winners" label="Winner" />
          <DateField source="createdAt" showTime label="Created At" />
          <DateField source="updatedAt" showTime label="Updated At" />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default GameHistory;
