import {
  List,
  DatagridConfigurable,
  TextField,
  DateField,
  Filter,
  SearchInput,
} from "react-admin";
import UserNameHyperlink from "../components/UserNameHyperlink";

const Feedbacks = (props: object) => {
  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "Username must be at least 10 characters long";
          }
          return undefined;
        }}
      />
    </Filter>
  );

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>Feedbacks</h2>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        filters={<UserNameFilter />}
      >
        <DatagridConfigurable bulkActionButtons={false}>
          <UserNameHyperlink label="User Name" source="userName" to="userId" />
          <TextField source="feedback" />
          <DateField source="createdAt" showTime />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default Feedbacks;
