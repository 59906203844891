import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { getItemWithExpiry } from "../utils/sessionData";
import config from "../config";
import { useFilePicker } from 'use-file-picker'

export default function GameConfig() {
  const [configState] = useState<any>({});
  const [disabled, setDisabled] = useState(true);
  const [openFileSelector, { filesContent }] = useFilePicker({
    accept: '.json',
    multiple: false
  });

  useEffect(() => {
    if (filesContent.length > 0) {
      setDisabled(false);
    }
  }, [filesContent])

  useEffect(() => {
    (async () => {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      // eslint-disable-next-line
      const { data } = await axios.get(
        config.REST_ENDPOINT +
        `/game-config?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
    })();
  }, []);

  const handleDownload = async () => {
    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }

      const response = await fetch(
        config.REST_ENDPOINT + `/game-config?key=${config.MAINTENANCE_BYPASS_KEY}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        const blob = new Blob([JSON.stringify(responseData)], {
          type: "application/json",
        });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "game-config.json";
        link.click();

        URL.revokeObjectURL(url);

        toast.success("Config data downloaded successfully!");
      } else {
        toast.error("Failed to download config data. Please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  const onSave = async () => {
    const { registrationTime } = configState;
    if (!dayjs(registrationTime).year()) {
      toast.error('Please input a valid date in the registration time!');
      return;
    }
    const fileResponse = JSON.parse(filesContent[0].content)

    const method = 'POST';
    const payload = fileResponse

    try {
      const user = getItemWithExpiry("user");
      if (!user.token) {
        toast.error("You have no access");
        return;
      }
      const response = await fetch(config.REST_ENDPOINT +
        `/game-config?key=${config.MAINTENANCE_BYPASS_KEY}`, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,

        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success('Config file updated successfully!');
      } else {
        toast.error('Failed to update game config. Please try again later.');
      }
    } catch (error) {
      toast.error('An error occurred. Please try again later.');
    }
  };
  
  return (
    <Paper
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 0.1, width: "25ch" },
        padding: "35px",
      }}
      noValidate
      autoComplete="off"
    >
      <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="center">
        <Button onClick={handleDownload} variant="contained" color="info" style={{ marginBottom: '1rem' }}>
          Download Current Config File
        </Button>
        <Button onClick={() => openFileSelector()} style={{ marginBottom: '1rem' }}>
          Upload Latest Config File
        </Button>
        {filesContent.map((file, index) => (
          <p key={index} style={{ marginBottom: '0.5rem' }}>
            {file.name}
          </p>
        ))}
        <Button disabled={disabled} onClick={onSave} variant="contained">
          Save
        </Button>
      </Box>
    </Paper>
  );
}
