import React from "react";
import Big from "big.js";
import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const RoundCard = ({ data }: any) => {
  const {
    roundNo,
    potAmount,
    tableCard,
    updatedAt,
    userInfo,
    winners,
    roundStartedAt,
  } = data;

  const PlayerRow = ({ data }: any) => {
    const {
      username,
      userId,
      playerAmount,
      betAmount,
      handCards,
      playerCardsInfo,
      status,
      winLossAmount,
    } = data;

    const isWinner = winners.includes(userId);

    const formatAmount = (amount: Big.BigSource) => {
      const formattedAmount = new Big(amount).toFixed(2);
      return formattedAmount;
    };

    return (
      <TableRow className={isWinner ? "winner-row" : ""}>
        <TableCell component="th" scope="row">
          <Link style={{ textDecoration: "none" }} to={`/users/${userId}/show`}>
            {username}
          </Link>
        </TableCell>
        <TableCell>{formatAmount(playerAmount)}</TableCell>
        <TableCell>{formatAmount(betAmount)}</TableCell>
        <TableCell>
          {winLossAmount && (
            <span
              style={{ color: parseFloat(winLossAmount) > 0 ? "green" : "red" }}
            >
              {formatAmount(winLossAmount)}
            </span>
          )}
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          {handCards?.length > 0 ? handCards.join(", ") : "NA"}
        </TableCell>
        <TableCell>{playerCardsInfo?.category || "NA"}</TableCell>
        <TableCell>
          {playerCardsInfo?.cards?.length > 0
            ? playerCardsInfo.cards.join(", ")
            : "NA"}
        </TableCell>
      </TableRow>
    );
  };

  const totalPlayers = userInfo.length;
  const updatedAtDate = new Date(updatedAt);
  const roundStartedAtDate = new Date(roundStartedAt);
  const durationInMilliseconds =
    updatedAtDate.getTime() - roundStartedAtDate.getTime();
  const hours = Math.floor(durationInMilliseconds / (60 * 60 * 1000));
  const minutes = Math.floor(
    (durationInMilliseconds % (60 * 60 * 1000)) / (60 * 1000)
  );
  const seconds = Math.floor((durationInMilliseconds % (60 * 1000)) / 1000);
  const duration = `${hours}h ${minutes}m ${seconds}s`;

  return (
    <Card>
      <CardHeader
        title={
          <React.Fragment>
            <b>Round Number:</b> {roundNo} | <b>Pot Amount:</b> {potAmount} |{" "}
            <b>Common Card:</b> {tableCard} | <b>Total Players:</b>{" "}
            {totalPlayers}
          </React.Fragment>
        }
        subheader={
          <React.Fragment>
            <b>Created At:</b> {new Date(roundStartedAt).toLocaleString()} |{" "}
            <b>Updated At:</b> {new Date(updatedAt).toLocaleString()} |{" "}
            <b>Duration:</b> {duration}
          </React.Fragment>
        }
      />
      <CardContent>
        <div style={{ marginBottom: "10px" }}>
          <h4>
            <b>Winners:</b>{" "}
            {userInfo
              .filter((user: any) => user.outcome === "won")
              .map((winner: any) => (
                <React.Fragment key={winner.userId}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/users/${winner.userId}/show`}
                  >
                    {winner.username}
                  </Link>
                  {", "}
                </React.Fragment>
              ))}
          </h4>
        </div>
        <div style={{ overflowX: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Username</b>
                </TableCell>
                <TableCell>
                  <b>Player Amount</b>
                </TableCell>
                <TableCell>
                  <b>Bet Amount</b>
                </TableCell>
                <TableCell>
                  <b>Win/Loss Amount</b>
                </TableCell>
                <TableCell>
                  <b>Status</b>
                </TableCell>
                <TableCell>
                  <b>Dealt Cards</b>
                </TableCell>
                <TableCell>
                  <b>Category</b>
                </TableCell>
                <TableCell>
                  <b>Best Hand Cards</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInfo.map((user: any) => (
                <PlayerRow key={user.userId} data={user} />
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
    </Card>
  );
};

export default RoundCard;
