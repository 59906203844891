import {
  TopToolbar,
  ExportButton,
  List,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  DateField,
  ChipField,
  Filter,
  SearchInput,
  FilterButton,
  FilterForm,
  SelectArrayInput,
  Button,
  useListContext,
  BooleanField,
  FunctionField,
  usePermissions,
} from "react-admin";
import { Stack } from "@mui/material";
import { useState } from "react";
import { getItemWithExpiry } from "../utils";
import { toast } from "react-toastify";
import axios from "axios";
import config from "../config";

const ProcessingRequests = (props: object) => {
  const [orderId, setOrderId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [, setCashfreeStatus] = useState("");
  const { permissions } = usePermissions();

  const transactionFilters = [
    <SelectArrayInput
      label="Filter by Payout Type"
      source="payoutType"
      choices={[
        { id: "UPI", name: "UPI" },
        { id: "BankTransfer", name: "Bank Transfer" },
      ]}
    />,
  ];
  const ListToolbar = () => (
    <Stack direction="row">
      <FilterForm filters={transactionFilters} />
    </Stack>
  );

  const ListActions = () => {
    const { data } = useListContext();
    setOrderId(data ? data[0].orderId : "");
    setCashfreeStatus(data ? data[0].cashfreePayoutStatus : "");

    return (
      <TopToolbar>
        <FilterButton filters={transactionFilters} />
        <SelectColumnsButton />
        {permissions.includes("admin") && <ExportButton />}
      </TopToolbar>
    );
  };

  const UserNameFilter = (props: any) => (
    <Filter {...props}>
      <SearchInput
        placeholder="Search UserName"
        source="userName"
        resettable
        alwaysOn
        validate={(value) => {
          if (value && value.length < 10) {
            return "Username must be at least 10 characters long";
          }
          return undefined;
        }}
      />
    </Filter>
  );

  const onVerify = () => {
    const user = getItemWithExpiry("user");
    if (!user.token) {
      toast.error("You have no right to refund transaction");
      return;
    }

    setDisabled(true);
    axios
      .patch(
        config.REST_ENDPOINT +
          `/payout/cashfree/verify` +
          `?key=${config.MAINTENANCE_BYPASS_KEY}`,
        { orderId },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        setCashfreeStatus(res.data.cashfreeStatus);
        setDisabled(false);
        toast.success(`Verified Successfully - ${res.data.cashfreeStatus}`);
      })
      .catch((err: any) => {
        setDisabled(false);
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <h2 style={{ fontFamily: "Playfair Display" }}>In Process</h2>

      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={<ListActions />}
        filters={<UserNameFilter />}
      >
        <ListToolbar />
        <DatagridConfigurable bulkActionButtons={false}>
          <TextField source="orderId" label="Order ID" />
          <TextField source="userName" label="User Name" />
          <TextField source="paymentMode" />
          <TextField source="currency" />
          <TextField source="amount" />
          <TextField source="paymentGateway" />
          <TextField source="payoutType" />
          <BooleanField source="autoPay" />
          <FunctionField
            label="Payout Status"
            render={(record: any) => {
              if (record.payoutStatus) {
                if (record.payoutStatus === "PENDING") {
                  return <ChipField source="payoutStatus" color="warning" />;
                } else if (record.payoutStatus === "SUCCESS") {
                  return <ChipField source="payoutStatus" color="success" />;
                } else {
                  return <ChipField source="payoutStatus" color="error" />;
                }
              } else {
                if (record.cashfreeStatus === "PENDING") {
                  return <ChipField source="cashfreeStatus" color="warning" />;
                } else if (record.cashfreeStatus === "SUCCESS") {
                  return <ChipField source="cashfreeStatus" color="success" />;
                } else {
                  return <ChipField source="cashfreeStatus" color="error" />;
                }
              }
            }}
          />
          <DateField source="createdAt" showTime label="Requested At" />
          <DateField source="updatedAt" showTime label="Updated At" />
          <FunctionField
            label="Action"
            render={(record: any) => {
              return (
                <>
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    disabled={disabled}
                    label={!disabled ? "Verify Now" : "Verifying"}
                    onClick={onVerify}
                  />
                </>
              );
            }}
          />
        </DatagridConfigurable>
      </List>
    </>
  );
};

export default ProcessingRequests;
